@value breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value sm, lg, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value black, lightGrey2 from "@depop/web-ui-kit/theme/default/colors.module.css";
@value borderRadiusMedium from "@depop/web-ui-kit/theme/default/border.module.css";

@layer components {
  .modalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 xl xl xl;
  }

  .depopSticker {
    height: 65px;
    width: 65px;
    transform: rotate(-18.5deg);
  }

  .modalTitle {
    margin-top: sm;
  }

  .modalBodyText {
    margin-top: sm;
  }

  .signupButton {
    margin-top: lg;
  }

  .loginButton {
    margin-top: sm;
  }

  .appDownloadContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: lg;
  }

  .downloadButtonContainer {
    width: 100%;

    @media breakpointMdUp {
      display: none;
    }
  }

  .horizontalBreakContainer {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: lg;
  }

  .horizontalBreak {
    height: 1px;
    flex-grow: 1;
    border: 1px solid lightGrey2;
  }

  .orText {
    padding: 0 lg;
  }

  .qrCodeContainer {
    display: none;

    @media breakpointMdUp {
      display: contents;
    }
  }

  .qrCode {
    width: 76px;
    padding: sm;
    border: 1px solid black;
    border-radius: borderRadiusMedium;
  }

  .qrCodeMainText {
    margin-top: sm;
  }
}
